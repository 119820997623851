import accountsConstants from './actionConstants';

const {
  TRANSACTION_POSTING_LOADING,
  TRANSACTION_POSTING_SUCCESS,
  TOGGLE_POSTING_MODAL,
} = accountsConstants;

const initialState = {
  pageLoading: false,
  openModal: false,
};

const postingReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case TRANSACTION_POSTING_LOADING: {
      const { payload } = action;
      const newState = { ...state, ...payload };
      return newState;
    }
    case TRANSACTION_POSTING_SUCCESS: {
        const { payload } = action;
        const newState = { ...state, ...payload };
        return newState;
      }
    case TOGGLE_POSTING_MODAL: {
      return {
        ...state,
        pageLoading: false,
        openModal: false,
      }
    }
    default:
      return state;
  }
};

export default postingReducer;