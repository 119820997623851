import maxisoft from "../assets/images/maxisoft_logo_jekalo_reverse.png";
import maxisoftLogin from '../assets/images/maxisoft_logo_jekalo.png';
import connershop from "../assets/images/connershop_logo.png";
import connershopLogin from "../assets/images/connershop_logo.png";
import haggai from "../assets/images/haggailogo-reverse.png";
import haggaiLogin from "../assets/images/haggailogo_main.png";
import o3capital from "../assets/images/03cards.jpeg";
import o3capitalLogin from "../assets/images/03cards.jpeg";

const images = {
    maxisoft,
    connershop,
    haggai,
    o3capital,
  };

  const imagesLogin = {
    maxisoftLogin,
    connershopLogin,
    haggaiLogin,
    o3capitalLogin
  };
  
  function getImageByKey(key, login = false) {
    if(login) {
        return imagesLogin[key+"Login"]
    } else {
        return images[key]
    }
  }
  
  export default getImageByKey