import React, { useState } from "react";
import { connect } from "react-redux";
import { createAccount } from "../../../actions/accountsActions";
import { createPosting, toggleModal } from "../../../actions/postingAction";
import {
  Col,
  Container,
  Row,
  Form,
  Input,
  FormGroup,
  Nav,
  NavbarBrand,
  Button,
  Label,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Loader from "./Loader";
import * as Yup from "yup";
import { useFormik, ErrorMessage } from "formik";
import "../../../assets/js/scripts.js";
import { getAccountName } from "../../../reducers/transfers";
import { getAccountDetails } from "../../../actions/postingAction";
import { toast } from "react-toastify";


const CashTransaction = (props) => {
  const { user } = window.localStorage;
  const { org_id: orgId } = JSON.parse(user);

  const handleSuccess = () => {
    props.toggleModal();
    props.history.push("/transactions/accountOpening");
  };

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      accountNameCheck: "",
      useAccount: "",
      accountNumber: "",
      naration: "",
      tellerNo: "",
      operationDate: "",
      accountBalance: "",
      accountName: "",
      amount: "",
      cashtransactionType: "",
      transactionType: "",
      transactionCue: "",
    },
    validationSchema: Yup.object({
      accountNameCheck: Yup.string().required("Account name check value is required").when("accountNumber", (accountNumber) => {
        if (accountNumber) {
          return Yup.string()
        }

      }),
      useAccount: Yup.string().required("Checkbox value is required"),
      accountNumber: Yup.string().required("Account number is required").min(10, "Invalid account number"),
      accountBalance: Yup.string().required("Account balance is required"),
      accountName: Yup.string().required("Account name is required"),
      naration: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Naration is required"),
      cashtransactionType: Yup.string().required(
        "Cash transaction type is required"
      ),
      operationDate: Yup.string().required("Operation date is required"),
      tellerNo: Yup.string().required("Teller/Cheque no is required"),
      amount: Yup.string().required("Transaction amount is required"),
      transactionType: Yup.string().required("Transaction type is required"),
      transactionCue: Yup.string().required("Transaction Cue is required"),
    }),
    onSubmit: (values) => {
      props.createPosting({
        use_account: values.useAccount,
        account_name_check: values.accountNameCheck,
        naration: values.naration,
        account_number: values.accountNumber,
        balance: values.accountBalance,
        account_name: values.accountName,
        cash_transaction_type: values.cashtransactionType,
        operation_date: values.operationDate,
        amount: values.amount,
        transaction_type: values.transactionType,
        transaction_cue: values.transactionCue,
        teller_cheque_no: values.tellerNo,
      });
      //alert(JSON.stringify(values, null, 2));
    },
  });

  // fieldNameValue: nuban or internal
  const handleUseAccount = (event, fieldNameValue) => {
    const checked = event.target.checked;
    if (checked === true) {
      formik.setFieldValue("useAccount", fieldNameValue, true);
    } else {
      formik.setFieldValue("useAccount", "", true);
    }
  };

  const handleAccountDetails = async (event) => {
    formik.setFieldValue("accountNumber", event.target.value, true);
    if (event.target.value.length > 9) {
      setLoading(true);
      const responseData = await getAccountDetails(
        event.target.value,
        orgId,
        false,
        true
      );
      console.log(responseData);
      if (responseData.getAccountName.balance) {
        formik.setFieldValue(
          "accountName",
          responseData.getAccountName.acct_name,
          true
        );
        formik.setFieldValue(
          "accountBalance",
          responseData.getAccountName.balance,
          true
        );
      } else {
        setLoading(false);
        toast.error("Invalid account number");
      }
      setLoading(false);
    }
  };

  const toggleModal = () => {
    props.toggleModal();
  };

  return (
    <Container>
      {loading | props.posting.pageLoading && <Loader />}
      <Row className="justify-content-center login-container align-items-center">
        <Modal isOpen={props.posting.openModal} toggle={() => toggleModal()}>
          <ModalHeader toggle={() => toggleModal()}>
            Cash Transaction Successfull
          </ModalHeader>
          <ModalBody>Cash Transaction Posting successfully created.</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => handleSuccess()}>
              Okay
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Col xs={12} md={12} lg={10}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <div className="text-center mb-4">
                <h6 className="h3 mb-2 font-weight-normal text-success">
                  Cash Transaction Entry
                </h6>
              </div>
            </FormGroup>

            <FormGroup>
              <Label for="accountNameCheck">
                <small className="font-weight-bold" className="font-weight-bold">Account Name Check</small>
              </Label>
              <Input
                type="select"
                name="accountNameCheck"
                id="accountNameCheck"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.accountNameCheck}
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option>3455553</option>
              </Input>
              {formik.touched.accountNameCheck &&
                formik.errors.accountNameCheck ? (
                <div className="text-danger small">
                  {formik.errors.accountNameCheck}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div class="form-check">
                  <Input
                    class="form-check-input"
                    type="checkbox"
                    checked={formik.values.useAccount === "Internal"}
                    id="useAccount"
                    name="useAccount"
                    onChange={(event) => handleUseAccount(event, "Internal")}
                    onBlur={formik.handleBlur}
                  />
                  <label class="form-check-label" for="useAccount">
                    <small>Use Internal Account</small>
                  </label>
                </div>
                <div class="form-check">
                  <Input
                    class="form-check-input"
                    type="checkbox"
                    checked={formik.values.useAccount === "NUBAN"}
                    id="useAccount"
                    name="useAccount"
                    onChange={(event) => handleUseAccount(event, "NUBAN")}
                    onBlur={formik.handleBlur}
                  />
                  <label class="form-check-label" for="flexCheckChecked">
                    <small>Use NUBAN Account</small>
                  </label>
                </div>
              </div>

              {formik.touched.useAccount && formik.errors.useAccount ? (
                <div className="text-danger small">
                  {formik.errors.useAccount}
                </div>
              ) : null}
            </FormGroup>
            <div className="form-row">
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="accountNumber">
                    <small className="font-weight-bold">Account Number</small>
                  </Label>
                  <Input
                    id="accountNumber"
                    name="accountNumber"
                    className="form-control"
                    onChange={handleAccountDetails}
                    onBlur={formik.handleBlur}

                    type="text"
                    defautValue={formik.values.accountNumber}
                  />
                  {formik.touched.accountNumber && formik.errors.accountNumber ? (
                    <div className="text-danger small">
                      {formik.errors.accountNumber}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="accountBalance">
                    <small className="font-weight-bold">Account Balance</small>
                  </Label>
                  <Input
                    name="accountBalance"
                    id="accountBalance"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.accountBalance}

                    disabled
                    type="text"
                  />
                  {formik.touched.accountBalance && formik.errors.accountBalance ? (
                    <div className="text-danger small">
                      {formik.errors.accountBalance}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="accountName">
                    <small className="font-weight-bold">Account Name</small>
                  </Label>
                  <Input
                    id="accountName"
                    name="accountName"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.accountName}
                    type="text"
                    disabled
                  />
                  {formik.touched.accountName && formik.errors.accountName ? (
                    <div className="text-danger small">
                      {formik.errors.accountName}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="naration">
                    <small className="font-weight-bold">Naration</small>
                  </Label>
                  <Input
                    id="naration"
                    name="naration"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.naration}
                    type="text"
                  />
                  {formik.touched.naration && formik.errors.naration ? (
                    <div className="text-danger small">
                      {formik.errors.naration}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="cashtransactionType">
                    <small className="font-weight-bold">Cash Transaction Type</small>
                  </Label>
                  <Input
                    type="select"
                    name="cashtransactionType"
                    id="cashtransactionType"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cashtransactionType}
                  >
                    <option value="" disabled>
                      Please Choose...
                    </option>
                    <option>Withdrawn</option>
                    <option>Deposit</option>
                  </Input>
                  {formik.touched.cashtransactionType &&
                    formik.errors.cashtransactionType ? (
                    <div className="text-danger small">
                      {formik.errors.cashtransactionType}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="operationDate">
                    <small className="font-weight-bold">Operation Date</small>
                  </Label>
                  <Input
                    id="operationDate"
                    name="operationDate"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.operationDate}
                    disabled
                    type="date"
                  />
                  {formik.touched.operationDate && formik.errors.operationDate ? (
                    <div className="text-danger small">
                      {formik.errors.operationDate}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="tellerNo">
                    <small className="font-weight-bold">Teller/Cheque Number</small>
                  </Label>
                  <Input
                    id=""
                    name="tellerNo"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tellerNo}
                    type="number"
                  />
                  {formik.touched.tellerNo && formik.errors.tellerNo ? (
                    <div className="text-danger small">
                      {formik.errors.tellerNo}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="Amount">
                    <small className="font-weight-bold">Amount</small>
                  </Label>
                  <Input
                    id="amount"
                    name="amount"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.amount}
                    type="number"
                  />
                  {formik.touched.amount && formik.errors.amount ? (
                    <div className="text-danger small">{formik.errors.amount}</div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-4">
                <FormGroup>
                  <Label for="transactionType">
                    <small className="font-weight-bold">Type of Transaction</small>
                  </Label>
                  <Input
                    type="select"
                    name="transactionType"
                    id="transactionType"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.transactionType}
                  >
                    <option value="" disabled>
                      Please Choose...
                    </option>
                    <option>Self</option>
                  </Input>
                  {formik.touched.transactionType &&
                    formik.errors.transactionType ? (
                    <div className="text-danger small">
                      {formik.errors.transactionType}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>

            <FormGroup>
              <Label for="transactionCue">
                <small className="font-weight-bold">Transaction Cue</small>
              </Label>
              <Input
                type="select"
                name="transactionCue"
                id="transactionCue"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.transactionCue}
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option>1</option>
              </Input>
              {formik.touched.transactionCue && formik.errors.transactionCue ? (
                <div className="text-danger small">
                  {formik.errors.transactionCue}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Button
                disabled={props.accounts.pageLoading}
                outline
                color="success"
                className="form-control bg-success text-white font-weight-bold text-uppercase"
                type="submit"
                value="Submit"
              >
                Create Transaction
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { accounts, posting } = state;
  return { accounts, posting };
};

const mapDispatchToProps = {
  createPosting,
  getAccountName,
  toggleModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CashTransaction);
