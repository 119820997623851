import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginPage from './loginSingup/Login';
import SignupPage from './MainNav/CustomerAccount';
import { toast } from 'react-toastify';
import Loader from './MainNav/Transactions/Loader';
import MainNav from './MainNav';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom';
import { setAdminStatus } from '../reducers/home';
import '../assets/js/scripts.js';
import '../assets/css/styles.css';

const storage = sessionStorage.getItem('status');
const parseStorage = JSON.parse(storage);

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: storage ? parseStorage : false,
      name: 'Adelaja Ibrahim Abiola',
      email: '',
      password: '',
      loginError: '',
      disableBtn: false,
    };
  }

  signIn = () => {
    const state = this.state;
    if (state.email.length < 1) {
      this.setState({ loginError: "Email can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else if (state.password.length < 1) {
      this.setState({ loginError: "Password can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else {
      const payload = {
        email: state.email,
        password: state.password,
      };
      this.setState({ disableBtn: true, loading: true }, () => {
        console.log('payload', payload);
        this.props.setAdminStatus(payload);
      });
    }
  };

  handlePassword = e => {
    this.setState({ password: e.target.value });
  };

  handleEmail = e => {
    this.setState({ email: e.target.value });
  };

  handleForm = e => {
    this.signIn();
    e.preventDefault();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.showAdminStatus !== this.props.showAdminStatus) {
      console.log('server', this.props.showAdminStatus);
      const { error, data } = this.props.showAdminStatus;
      if (data && Object.keys(data).length) {
        const { adminSignIn } = data;
        this.setState({ adminSignIn, disableBtn: false, loading: false }, () => {
          sessionStorage.setItem('status', true);
          localStorage.setItem('token', JSON.stringify(adminSignIn.token));
          localStorage.setItem('user', JSON.stringify(adminSignIn.user));
          window.location.href = '/';
        });
      } else if (error && Object.keys(error).length) {
        this.setState({ disableBtn: false, loading: false }, () => {
          toast.error(error.errors[0].message);
        });
      } else {
        this.setState({ disableBtn: false, loading:false }, () => {
          toast.error('Network error!');
        });
      }
    }
  };

  render() {
    return (
      <div>
        {this.state.loading && <Loader />}
        {(this.state.login && <MainNav />) || (
          <LoginPage
            // signIn={this.signIn}
            email={this.handleEmail}
            emailValue={this.state.email}
            password={this.handlePassword}
            passwordValue={this.state.password}
            form={this.handleForm}
            disableBtn={this.state.disableBtn}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showAdminStatus: state.home.showAdminStatus,
});

const mapDispatchToProps = {
  setAdminStatus,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
);
//export default Home
