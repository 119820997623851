import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import { toast } from "react-toastify";
import actionConstants from "../reducers/actionConstants";
const _DEV = "localhost";
let URL_PATH = "/api/graphql";
if (window.location.hostname === _DEV) {
  URL_PATH = `http://localhost:4000/api/graphql`;
}

export const getAccountDetails = async (
  acct_number,
  bank_code,
  dashboard,
  origin
) => {
  const client = new ApolloClient({
    uri: `${URL_PATH}`,
  });
  try {
    const response = await client.query({
      variables: {
        acct_number,
        bank_code,
        dashboard,
        origin,
      },
      query: gql`
        query GetAccountInfo(
          $acct_number: String
          $bank_code: String!
          $dashboard: Boolean
          $origin: Boolean
        ) {
          getAccountName(
            acct_number: $acct_number
            bank_code: $bank_code
            dashboard: $dashboard
            origin: $origin
          ) {
            acct_name
            bank_name
            balance
          }
        }
      `,
    });
    console.log(response.data)
    return response.data;
  } catch (err) {
    console.log(err)
    toast.error(err.message);
  }
};

export const createPosting = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: actionConstants.TRANSACTION_POSTING_LOADING,
      payload: {
        pageLoading: true,
        openModal: false,
      },
    });

    try {
      const { user } = window.localStorage;

      const { org_id: orgId, staff_id } = JSON.parse(user);

      const {
        use_account,
        account_name_check,
        naration,
        account_number,
        balance,
        account_name,
        cash_transaction_type,
        operation_date,
        teller_cheque_no,
        amount,
        transaction_type,
        transaction_cue,
        value_date,
        GL_account_code_id,
        account_title,
        stamp_duty_charge,
      } = payload;

      const client = new ApolloClient({
        uri: `${URL_PATH}`,
        request: (operation) => {
          const token = localStorage.getItem("token");
          operation.setContext({
            headers: {
              authorization: token ? token : "",
            },
          });
        },
      });
      const newPostingResponse = await client.mutate({
        variables: {
          use_account,
          account_name_check,
          naration,
          account_number,
          balance,
          account_name,
          cash_transaction_type,
          operation_date,
          teller_cheque_no,
          amount,
          transaction_type,
          transaction_cue,
          value_date,
          GL_account_code_id,
          account_title,
          stamp_duty_charge,
          staff_id,
          org_id: orgId,
        },
        mutation: gql`
          mutation CreatePosting(
            $use_account: String!
            $account_name_check: String!
            $naration: String!
            $account_number: String!
            $balance: String!
            $account_name: String!
            $cash_transaction_type: String
            $operation_date: String!
            $teller_cheque_no: Int!
            $amount: Int!
            $transaction_type: String
            $transaction_cue: String
            $value_date: String
            $GL_account_code_id: String
            $account_title: String
            $staff_id: String!
            $stamp_duty_charge: String
            $org_id: String!
          ) {
            posting(
              use_account: $use_account
              account_name_check: $account_name_check
              naration: $naration
              account_number: $account_number
              balance: $balance
              account_name: $account_name
              cash_transaction_type: $cash_transaction_type
              operation_date: $operation_date
              teller_cheque_no: $teller_cheque_no
              amount: $amount
              transaction_type: $transaction_type
              transaction_cue: $transaction_cue
              value_date: $value_date
              GL_account_code_id: $GL_account_code_id
              account_title: $account_title
              staff_id: $staff_id
              stamp_duty_charge: $stamp_duty_charge
              org_id: $org_id
            ) {
              status
              message
            }
          }
        `,
      });
      console.log(newPostingResponse, "*********");
      //const { updateUserAccount } = newAccountResponse.data;
      if (newPostingResponse.error) {
        console.log(newPostingResponse.error);
        toast.error(newPostingResponse.errors[0].message);
        dispatch({
          type: actionConstants.TRANSACTION_POSTING_LOADING,
          payload: {
            pageLoading: false,
            openModal: false,
          },
        });
      } else {
        //console.log(newAccountResponse.data)

        dispatch({
          type: actionConstants.TRANSACTION_POSTING_SUCCESS,
          payload: {
            pageLoading: false,
            openModal: true,
          },
        });
      }
    } catch (err) {
      console.log(err.message, "Error here%%%%%%");
      dispatch({
        type: actionConstants.TRANSACTION_POSTING_LOADING,
        payload: {
          pageLoading: false,
          openModal: false,
        },
      });
      toast.error(err.message);
    }
  };
};


export const toggleModal = () => {
  return async (dispatch) => {
    dispatch({
      type: actionConstants.TOGGLE_POSTING_MODAL,
    });

    
  };
};
