import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
const Pagination = props => {
  const { itemCount, pageSize, currentPage, onPageChange, pagesCount: propsPageCount } = props;
  let pagesCount;
  if (propsPageCount) {
    pagesCount = propsPageCount
  } else {
    pagesCount = Math.ceil(itemCount / pageSize);
  }
  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);
  return (
    <nav>
      <ul className="pagination">
        {pages.map(page => (
          <li
            key={page}
            className={page === currentPage ? "page-item active" : "page-item"}
          >
            <a
              className="page-link"
              onClick={() => onPageChange(page)}
              style={{ cursor: "pointer" }}
            >
              {page}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};
// Pagination.propTypes = {
//   itemCount: PropTypes.number.isRequired,
//   pageSize: PropTypes.number.isRequired,
//   currentPage: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired
// };
export default Pagination;
