import React from 'react';
// import { connect } from 'react-redux';
import maxisoft from '../../../assets/images/maxisoft_logo_jekalo.png';
import {
  Col,
  Container,
  Row,
  Form,
  Input,
  FormGroup,
  Nav,
  NavbarBrand,
  Button,
  Label
} from 'reactstrap';
// import { setAdminStatus } from '../../../reducers/home';
import '../../../assets/js/scripts.js';
import {
  brandname
} from "../../../actions/permission";
import getImageByKey from "../../getImageByKey";
// import { withRouter } from 'react-router';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
// import MainNav from '../../MainNav';

// export class LoginPage extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { login: false };
//   }

//   signIn = () => {
//     this.props.setAdminStatus(true);
//   };

//   componentDidUpdate = (prevProps, prevState) => {
//     if (prevProps.showAdminStatus !== this.props.showAdminStatus) {
//       this.setState({ login: this.props.showAdminStatus }, ()=>
//         this.props.history.push('/dashboard', {login:true})
//         );
//     }
//   };

//   render() {
const LoginPage = ({
  disableBtn,
  form,
  email,
  password,
  emailValue,
  passwordValue
}) => {
  return (
    <Container className=''>
      <Nav>
        <NavbarBrand href='/'>
        <img src={getImageByKey(brandname(), true)} alt="" width="155px" />
        </NavbarBrand>
      </Nav>
      <Row className='justify-content-center login-container align-items-center'>
        <Col xs={12} md={6} lg={5}>
          <Form className='shadow p-4'>
            <FormGroup>
              <div className='text-center mb-4'>
                <h6 className='h3 mb-2 font-weight-normal'>Login</h6>
              </div>
            </FormGroup>
            <FormGroup>
              <Label for='email'>
                <small>Email</small>
              </Label>
              <Input
                id='email'
                className='form-control'
                placeholder='Email'
                onChange={email}
                value={emailValue}
                required
                type='text'
              />
            </FormGroup>
            <FormGroup>
              <Label for='password'>
                <small>Password</small>
              </Label>
              <Input
                id='password'
                className='form-control'
                placeholder='Password'
                onChange={password}
                value={passwordValue}
                required
                type='password'
              />
            </FormGroup>
            <FormGroup>
              <Button
                outline
                color='success'
                className='form-control bg-success text-white font-weight-bold text-uppercase'
                onClick={form}
                disabled={disableBtn}
                type='submit'
                value='Submit'
              >
                Log in
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
    // <div className="login-container">
    //   <div /*onSubmit={form}*/ className="form-signin">
    //     <div className="text-center mb-4">
    //       <img className="mb-4" src={maxisoft} alt="" width="250px" />
    //       <h1 className="h3 mb-3 font-weight-normal">ADMIN LOGIN</h1>
    //     </div>

    //     <div className="form-label-group">
    //       <input
    //         type="text"
    //         className="form-control"
    //         placeholder="Email"
    //         onChange={email}
    //         value={emailValue}
    //         required
    //         autoFocus
    //       />
    //       <label htmlFor="inputEmail">Email</label>
    //     </div>

    //     <div className="form-label-group">
    //       <input
    //         type="password"
    //         id="inputPassword"
    //         className="form-control"
    //         placeholder="Password"
    //         onChange={password}
    //         value={passwordValue}
    //         required
    //       />
    //       <label htmlFor="inputPassword">Password</label>
    //     </div>

    //     <div className="checkbox mb-3">
    //       <label>
    //         <input type="checkbox" value="remember-me" /> Remember me
    //       </label>
    //     </div>
    //     <input
    //       onClick={form}
    //       disabled={disableBtn}
    //       className="btn btn-lg btn-success btn-block"
    //       type="submit"
    //       value="Submit"
    //     />
    //     <p className="mt-5 mb-3 text-muted text-center">&copy; 2019</p>
    //   </div>
    // </div>
  );
};
export default LoginPage;

// const mapStateToProps = state => ({
//   showAdminStatus: state.home.showAdminStatus
// });

// const mapDispatchToProps = {
//   setAdminStatus
// };

// export default withRouter(
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(LoginPage)
// );
