import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table, Container, Button } from 'reactstrap';
import Pagination from './commons/pagination';
import { paginate } from './utils/paginate';
import { getTransaction, addNewUser } from '../../../reducers/home';
import { navPermission } from '../../../actions/permission';
class Teller extends Component {
  state = {
    accountName: 'Account Name',
    accountNumber: 'Account Number',
    amount: 'Amount',
    teller_no: 'Teller No',
    trans_type: 'Trans Type',
    pageSize: 10,
    currentPage: 1
  };
  componentDidMount() {
    if (!navPermission(2)) {
      return (window.location.href = '/');
    } else {
      const { user } = window.localStorage;
      const { org_id: orgId } = JSON.parse(user);
      this.props.getTransaction({ orgId });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showGetTransaction !== this.props.showGetTransaction) {
      console.log('got here!!', this.props.showGetTransaction);
    }
  }
  populateTransactions = showGetTransaction => {
    let startId = 1;
    const transactionsList = showGetTransaction.map(transaction => {
      const {
        accountNumber,
        amount,
        teller_no,
        trans_type,
        name,
        id,
        created_at
      } = transaction;

      return (
        <tr className='tr' key={id}>
          <th scope='row'>{startId++}</th>
          <td>{name}</td>
          <td>{accountNumber}</td>
          <td>{`NGN ${amount}`}</td>
          <td>{teller_no}</td>
          <td className='text-center'>
            <strong
              className={`${
                trans_type === 'CR' ? 'text-success' : 'text-danger'
              }`}
            >
              <span className='mr-2'>
                {trans_type === 'CR' ? (
                  <i className='fas fa-level-up-alt'></i>
                ) : (
                  <i className='fas fa-level-down-alt'></i>
                )}
              </span>
              {trans_type}
            </strong>
          </td>
          <td>{created_at}</td>
        </tr>
      );
    });
    return transactionsList;
  };
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };
  render() {
    const { showGetTransaction } = this.props;
    const { pageSize, currentPage } = this.state;
    const datas = paginate(showGetTransaction, currentPage, pageSize);
    console.log(datas, 'data');
    return (
      <Container>
        <Row>
          <Col>
            <Table borderless>
              <thead>
                <tr className='text-uppercase'>
                  <th></th>
                  <th>
                    <small className='font-weight-bold'>Account Name</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Account Number</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Amount</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Teller No</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Trans type</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Date</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {showGetTransaction && this.populateTransactions(datas)}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            {showGetTransaction && (
              <Pagination
                itemCount={showGetTransaction.length}
                pageSize={pageSize}
                onPageChange={this.handlePageChange}
                currentPage={currentPage}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  showNewAdmin: state.home.showNewAdmin,
  showGetTransaction: state.home.showGetTransaction,
  showNewUser: state.home.showNewUser
});

const mapDispatchToProps = {
  getTransaction,
  addNewUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Teller);
