import React, { useState } from "react";
import { connect } from "react-redux";
import { createAccount } from "../../../actions/accountsActions";
import maxisoft from "../../../assets/images/maxisoft_logo_jekalo.png";
import {
  Col,
  Container,
  Row,
  Form,
  Input,
  FormGroup,
  Nav,
  NavbarBrand,
  Button,
  Label,
  Spinner,
} from "reactstrap";
import Loader from '../Transactions/Loader';
import * as Yup from "yup";
import { useFormik, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import ReactFileReader from "react-file-reader";
import imageToBase64 from "image-to-base64/browser";
// import { setAdminStatus } from '../../../reducers/home';
import "../../../assets/js/scripts.js";
// import { withRouter } from 'react-router';
// import { BrowserRouter as Router, Route } from 'react-router-dom';
// import MainNav from '../../MainNav';

// export class LoginPage extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { login: false };
//   }

//   signIn = () => {
//     this.props.setAdminStatus(true);
//   };

//   componentDidUpdate = (prevProps, prevState) => {
//     if (prevProps.showAdminStatus !== this.props.showAdminStatus) {
//       this.setState({ login: this.props.showAdminStatus }, ()=>
//         this.props.history.push('/dashboard', {login:true})
//         );
//     }
//   };

//   render() {
const CustomerAccount = (props) => {
  const formik = useFormik({
    initialValues: {
      accountType: "",
      accountId: "",
      branchCode: "",
      street: "",
      state: "",
      tellNo: "",
      faxNo: "",
      email: "",
      openingDate: "",
      status: "",
      address: "",
      picture: "",
      identification: "",
      id_image: "",
      organizationType: "",
      sectorCategory: "",
      sex: "",
      incorp: "",
      nextOfKin: "",
      nextOfKinAddress: "",
      phonenextOfKin: "",
      relationshipnextofkin: "",
      taxNumber: "",
      bvn: "",
      firstName: "",
      lastName: "",
      cotRate: '',
      depositRate: "",
      debitRate: ""
    },
    validationSchema: Yup.object({
      accountType: Yup.string().required("Account type is required"),
      accountId: Yup.string().required("Account ID is required"),
      branchCode: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Branch code is required"),
      firstName: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("First name is required"),
      lastName: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Last name is required"),
      street: Yup.string().required("Street value is required"),
      state: Yup.string().required("State value is required"),
      tellNo: Yup.string()
        .length(11, "Tell no must be 11 digits")
        .required("Tell number is required"),
      faxNo: Yup.string().length(11, "Tell no must be 11 digits"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      openingDate: Yup.string().required("Opening date is required"),
      status: Yup.string().required("Status is required"),
      address: Yup.string().required("Address is required"),
      picture: Yup.string().required("Photo is required"),
      identification: Yup.string().required("Identification is required"),
      id_image: Yup.string().required("Identification image is required"),
      organizationType: Yup.string().required("Organization type is required"),
      sectorCategory: Yup.string().required("Sector Category is required"),
      sex: Yup.string().required("Sex is required"),
      incorp: Yup.string().required("Incorp is required"),
      nextOfKin: Yup.string().required("Next of Kin is required"),
      nextOfKinAddress: Yup.string().required(
        "Next of kin address is required"
      ),
      phonenextOfKin: Yup.string()
      .length(11, "Next of kin phone number must be 11 digits")
      .required(
        "Next of kin phone number is required"
      ),
      relationshipnextofkin: Yup.string().required(
        "Next of kin relationship is required"
      ),
      taxNumber: Yup.string().required("Tax number is required"),
      bvn: Yup.string().required("BVN is required"),
      debitRate: Yup.string().required("Debit rate is required"),
      cotRate: Yup.string().required("COT rate is required"),
      depositRate: Yup.string().required("Deposit rate is required"),
    }),
    onSubmit: (values) => {
      const {
        accountType,
        accountId,
        branchCode,
        street,
        state,
        tellNo,
        faxNo,
        email,
        openingDate,
        status,
        address,
        picture,
        identification,
        id_image,
        organizationType,
        sectorCategory,
        sex,
        incorp,
        nextOfKin,
        nextOfKinAddress,
        phonenextOfKin,
        relationshipnextofkin,
        taxNumber,
        bvn,
        firstName,
        lastName,
        debitRate,
        depositRate,
        cotRate,
      } = values;
      // imageToBase64(values.id_image)
      //   .then((va) => console.log(va))
      //   .catch((er) => console.log(er));

      props.createAccount({
        first_name: firstName,
        phone_id: tellNo,
        surname: lastName,
        email,
        address: address,
        phone_number: tellNo,
        customer_id: tellNo,
        state: state,
        identification_type: identification,
        account_type: accountType,
        sector: sectorCategory,
        organization_type: organizationType,
        id_image,
        picture,
        branch_code: branchCode,
        account_id: accountId,
        account_name: `${firstName} ${lastName}`,
        street,
        tell_no: tellNo,
        fax_no: faxNo,
        opening_date: openingDate,
        account_status: status,
        sex,
        birthday_incorp: incorp,
        next_of_kin: nextOfKin,
        next_of_kin_address: nextOfKinAddress,
        next_of_kin_phone_no: phonenextOfKin,
        next_of_kin_relation: relationshipnextofkin,
        tax_number: taxNumber,
        bvn,
        cot_rate: cotRate,
        deposit_rate: depositRate,
        debit_rate: debitRate,
      });
      //alert(JSON.stringify(values, null, 2));
    },
  });

  const [photoFileName, setPhotoFileName] = useState("");
  const [idFileName, setIdFileName] = useState("");

  const handleFiles = (files, fieldName) => {
    //console.log(files);
    if (fieldName === "picture") {
      setPhotoFileName(files.fileList[0].name);
    } else {
      setIdFileName(files.fileList[0].name);
    }
    formik.setFieldValue(fieldName, files.base64, true);
  };
  return (
    <Container>
      {props.accounts.pageLoading && <Loader />}
      <Row className="justify-content-center login-container align-items-center">
        <Col xs={12} md={6} lg={5}>
          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <div className="text-center mb-4">
                <h6 className="h3 mb-2 font-weight-normal text-success">
                  Customer Account Opening Form
                </h6>
              </div>
            </FormGroup>

            <FormGroup>
              <Label for="accountType">
                <small>Account Type</small>
              </Label>
              <Input
                type="select"
                name="accountType"
                id="accountType"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.accountType}
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option>Staff Current Account</option>
                <option>Asset Matrix Saving Account (OBA)</option>
                <option>Asset Matrix Saving Account (IKUN)</option>
                <option>Asset Matrix Saving Account (SUPARE)</option>
                <option>Classified Debt Account</option>
                <option>Staff Salary Schedule</option>
                <option>Asset Matrix Current Account</option>
                <option>Asset Matrix Current Account (IKUN)</option>
              </Input>
              {formik.touched.accountType && formik.errors.accountType ? (
                <div className="text-danger small">
                  {formik.errors.accountType}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="accountId">
                <small>Account ID</small>
              </Label>
              <Input
                id="accountId"
                name="accountId"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.accountId}
                required
                type="text"
              />
              {formik.touched.accountId && formik.errors.accountId ? (
                <div className="text-danger small">
                  {formik.errors.accountId}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="branchCode">
                <small>Branch Code</small>
              </Label>
              <Input
                type="select"
                name="branchCode"
                id="branchCode"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.branchCode}
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option>10</option>
              </Input>
              {formik.touched.branchCode && formik.errors.branchCode ? (
                <div className="text-danger small">
                  {formik.errors.branchCode}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="firstname">
                <small>Firstname</small>
              </Label>
              <Input
                id="firstName"
                name="firstName"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                required
                type="text"
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="text-danger small">
                  {formik.errors.firstName}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="lastname">
                <small>Lastname</small>
              </Label>
              <Input
                id="lastName"
                name="lastName"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                type="text"
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="text-danger small">
                  {formik.errors.lastName}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="Street">
                <small>Street</small>
              </Label>
              <Input
                name="street"
                id="street"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.street}
                required
                type="text"
              />
              {formik.touched.street && formik.errors.street ? (
                <div className="text-danger small">{formik.errors.street}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="state">
                <small>State</small>
              </Label>
              <Input
                type="select"
                name="state"
                id="state"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option value="Abia">Abia</option>
                <option value="Adamawa">Adamawa</option>
                <option value="Akwa Ibom">Akwa Ibom</option>
                <option value="Anambra">Anambra</option>
                <option value="Bauchi">Bauchi</option>
                <option value="Bayelsa">Bayelsa</option>
                <option value="Benue">Benue</option>
                <option value="Borno">Borno</option>
                <option value="Cross Rive">Cross River</option>
                <option value="Delta">Delta</option>
                <option value="Ebonyi">Ebonyi</option>
                <option value="Edo">Edo</option>
                <option value="Ekiti">Ekiti</option>
                <option value="Enugu">Enugu</option>
                <option value="FCT">Federal Capital Territory</option>
                <option value="Gombe">Gombe</option>
                <option value="Imo">Imo</option>
                <option value="Jigawa">Jigawa</option>
                <option value="Kaduna">Kaduna</option>
                <option value="Kano">Kano</option>
                <option value="Katsina">Katsina</option>
                <option value="Kebbi">Kebbi</option>
                <option value="Kogi">Kogi</option>
                <option value="Kwara">Kwara</option>
                <option value="Lagos">Lagos</option>
                <option value="Nasarawa">Nasarawa</option>
                <option value="Niger">Niger</option>
                <option value="Ogun">Ogun</option>
                <option value="Ondo">Ondo</option>
                <option value="Osun">Osun</option>
                <option value="Oyo">Oyo</option>
                <option value="Plateau">Plateau</option>
                <option value="Rivers">Rivers</option>
                <option value="Sokoto">Sokoto</option>
                <option value="Taraba">Taraba</option>
                <option value="Yobe">Yobe</option>
                <option value="Zamfara">Zamfara</option>
              </Input>
              {formik.touched.state && formik.errors.state ? (
                <div className="text-danger small">{formik.errors.state}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="tellNo">
                <small>Tell No</small>
              </Label>
              <Input
                id="tellNo"
                name="tellNo"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tellNo}
                type="text"
              />
              {formik.touched.tellNo && formik.errors.tellNo ? (
                <div className="text-danger small">{formik.errors.tellNo}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="Fax no">
                <small>Fax No</small>
              </Label>
              <Input
                id="faxNo"
                name="faxNo"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.faxNo}
                type="text"
              />
              {formik.touched.faxNo && formik.errors.faxNo ? (
                <div className="text-danger small">{formik.errors.faxNo}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="email">
                <small>Email</small>
              </Label>
              <Input
                id="email"
                name="email"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                required
                type="text"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger small">{formik.errors.email}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label for="openingDate">
                <small>Opening Date</small>
              </Label>
              <Input
                id="openingDate"
                name="openingDate"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.openingDate}
                required
                type="date"
              />
              {formik.touched.openingDate && formik.errors.openingDate ? (
                <div className="text-danger small">
                  {formik.errors.openingDate}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="status">
                <small>Status</small>
              </Label>
              <Input
                type="select"
                name="status"
                id="status"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.status}
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option>Active</option>
                <option>Domant</option>
              </Input>
              {formik.touched.status && formik.errors.status ? (
                <div className="text-danger small">{formik.errors.status}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="address">
                <small>Address</small>
              </Label>
              <Input
                id="address"
                name="address"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.officeAddress}
                required
                type="text"
              />
              {formik.touched.officeAddress && formik.errors.officeAddress ? (
                <div className="text-danger small">
                  {formik.errors.officeAddress}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="picture">
                <small>Photo</small>
              </Label>

              <ReactFileReader
                handleFiles={(file) => handleFiles(file, "picture")}
                base64={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                <div>
                  <button
                    type="button"
                    class="btn btn-light"
                    style={{
                      borderRadius: 4,
                      height: 35,
                      borderWidth: 1,
                      borderColor: "gray",
                      marginRight: 8,
                      fontSize: 14,
                    }}
                  >
                    Choose File
                  </button>
                  <text style={{ fontSize: 12 }}>
                    {formik.values.picture ? photoFileName : "No file chosen"}
                  </text>
                </div>
              </ReactFileReader>
              {formik.touched.picture && formik.errors.picture ? (
                <div className="text-danger small">{formik.errors.picture}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="identification">
                <small>Identifcation</small>
              </Label>
              <Input
                type="select"
                name="identification"
                id="identification"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.identification}
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option>National ID Card</option>
              </Input>
              {formik.touched.identification && formik.errors.identification ? (
                <div className="text-danger small">
                  {formik.errors.identification}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="picture">
                <small>ID Image</small>
              </Label>

              <ReactFileReader
                handleFiles={(file) => handleFiles(file, "id_image")}
                base64={true}
              >
                <div>
                  <button
                    type="button"
                    class="btn btn-light"
                    style={{
                      borderRadius: 4,
                      height: 35,
                      borderWidth: 1,
                      borderColor: "gray",
                      marginRight: 8,
                      fontSize: 14,
                    }}
                  >
                    Choose File
                  </button>
                  <text style={{ fontSize: 12 }}>
                    {formik.values.id_image ? idFileName : "No file chosen"}
                  </text>
                </div>
              </ReactFileReader>

              {formik.touched.id_image && formik.errors._id ? (
                <div className="text-danger small">
                  {formik.errors.id_image}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="organizationType">
                <small>Organization Type</small>
              </Label>
              <Input
                type="select"
                name="organizationType"
                id="organizationType"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.organizationType}
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option>Individual</option>
                <option>Solidarity Group</option>
                <option>Neighborhood and Small Group Revolving Funds</option>
                <option>Village Banking</option>
                <option>Wholesales Lending</option>
                <option>Credit Union</option>
                <option>Staff</option>
                <option>Corporate Body</option>
                <option>Micro Finance Bank</option>
              </Input>
              {formik.touched.organizationType &&
              formik.errors.organizationType ? (
                <div className="text-danger small">
                  {formik.errors.organizationType}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="sectorCategory">
                <small>Sector Category</small>
              </Label>
              <Input
                type="select"
                name="sectorCategory"
                id="sectorCategory"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sectorCategory}
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option>Agriculture and Forestry</option>
                <option>Mining and Quary</option>
                <option>Manufacturing and Food Processing</option>
                <option>Trade and Commerce</option>
                <option>Transport and Communication</option>
                <option>Real Estate and Construction</option>
                <option>Rent/Housing</option>
                <option>Consumer/Personal</option>
                <option>Health</option>
                <option>Education</option>
                <option>Tourism and Hospitality</option>
                <option>Purchase of Shares</option>
                <option>Staff</option>
              </Input>
              {formik.touched.sectorCategory && formik.errors.sectorCategory ? (
                <div className="text-danger small">
                  {formik.errors.sectorCategory}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="sex">
                <small>Sex</small>
              </Label>
              <Input
                type="select"
                name="sex"
                id="sex"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sex}
                className="form-control"
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option value="1001">MALE</option>
                <option value="1002">FEMALE</option>
              </Input>
              {formik.touched.sex && formik.errors.sex ? (
                <div className="text-danger small">{formik.errors.sex}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="birthday">
                <small>Birthday / Incorp</small>
              </Label>
              <Input
                id="incorp"
                name="incorp"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.incorp}
                required
                type="date"
              />
              {formik.touched.incorp && formik.errors.incorp ? (
                <div className="text-danger small">{formik.errors.incorp}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="nextOfKin">
                <small>Next of Kin</small>
              </Label>
              <Input
                id="nextOfKin"
                name="nextOfKin"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nextOfKin}
                required
                type="text"
              />
              {formik.touched.nextOfKin && formik.errors.nextOfKin ? (
                <div className="text-danger small">
                  {formik.errors.nextOfKin}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="nextOfKinaddress">
                <small>Next of Kin Address</small>
              </Label>
              <Input
                id="nextOfKinAddress"
                name="nextOfKinAddress"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nextOfKinAddress}
                required
                type="text"
              />
              {formik.touched.nextOfKinAddress &&
              formik.errors.nextOfKinAddress ? (
                <div className="text-danger small">
                  {formik.errors.nextOfKinAddress}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="phonenextOfKin">
                <small>Phone Number of Next of Kin</small>
              </Label>
              <Input
                id="phonenextOfKin"
                name="phonenextOfKin"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phonenextOfKin}
                required
                type="text"
              />
              {formik.touched.phonenextOfKin && formik.errors.phonenextOfKin ? (
                <div className="text-danger small">
                  {formik.errors.phonenextOfKin}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="relationshipnextofkin">
                <small>Relationship to Next of Kin</small>
              </Label>
              <Input
                type="select"
                name="relationshipnextofkin"
                id="relationshipnextofkin"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.relationshipnextofkin}
                className="form-control"
              >
                <option value="" disabled>
                  Please Choose...
                </option>
                <option>Wife</option>
                <option>Husband</option>
                <option>Father</option>
                <option>Mother</option>
                <option>Son</option>
                <option>Daughter</option>
                <option>Cousin</option>
                <option>Niece</option>
                <option>Husband</option>
              </Input>
              {formik.touched.relationshipnextofkin &&
              formik.errors.relationshipnextofkin ? (
                <div className="text-danger small">
                  {formik.errors.relationshipnextofkin}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="taxNumber">
                <small>Tax Identification Number</small>
              </Label>
              <Input
                id="taxNumber"
                name="taxNumber"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.taxNumber}
                required
                type="text"
              />
              {formik.touched.taxNumber && formik.errors.taxNumber ? (
                <div className="text-danger small">
                  {formik.errors.taxNumber}
                </div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="bvn">
                <small>BVN</small>
              </Label>
              <Input
                id="bvn"
                name="bvn"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bvn}
                required
                type="text"
              />
              {formik.touched.bvn && formik.errors.bvn ? (
                <div className="text-danger small">{formik.errors.bvn}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="cotRate">
                <small>COT Rate</small>
              </Label>
              <Input
                id="cotRate"
                name="cotRate"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cotRate}
                required
                type="text"
              />
              {formik.touched.cotRate && formik.errors.cotRate ? (
                <div className="text-danger small">{formik.errors.cotRate}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="depositRate">
                <small>Deposit Rate</small>
              </Label>
              <Input
                id="depositRate"
                name="depositRate"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.depositRate}
                required
                type="text"
              />
              {formik.touched.depositRate && formik.errors.depositRate ? (
                <div className="text-danger small">{formik.errors.depositRate}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Label for="debitRate">
                <small>Debit Rate</small>
              </Label>
              <Input
                id="debitRate"
                name="debitRate"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.debitRate}
                required
                type="text"
              />
              {formik.touched.debitRate && formik.errors.debitRate ? (
                <div className="text-danger small">{formik.errors.debitRate}</div>
              ) : null}
            </FormGroup>

            <FormGroup>
              <Button
                disabled={props.accounts.pageLoading}
                outline
                color="success"
                className="form-control bg-success text-white font-weight-bold text-uppercase"
                type="submit"
                value="Submit"
              >
                Create Account
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const { accounts } = state;
  return { accounts };
};

const mapDispatchToProps = {
  createAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAccount);
